import styled from "styled-components"

import { PrintedAddressSummary } from "src/components/Account/BillingPortal/BillingAddress/PrintedAddress"
import { IBillingAddress } from "src/components/Account/types"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { backgroundGrayV2 } from "src/ui/colors"
import CardIcon from "src/ui/icons/card.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { RadioGroup } from "src/ui/RadioGroup/RadioGroup"
import { Show } from "src/ui/Show/Show"
import { spacing } from "src/ui/spacing"

export function PaymentMethodStep({
  cardEndDigits,
  billingAddress,
  vatNumber,
}: {
  cardEndDigits: string | undefined
  billingAddress: IBillingAddress | undefined
  vatNumber: string | undefined
}) {
  const { t, langKeys } = useTranslate()

  return (
    <Box>
      <Show if={!cardEndDigits}>
        <MBanner type="warning">
          <div>
            <span>{t(langKeys.no_payment_method_instructions)}</span>{" "}
            <StyledAppLink to={Routes.AccountBilling.location()}>
              {t(langKeys.edit_settings)}
            </StyledAppLink>
          </div>
        </MBanner>
      </Show>

      <Show if={!!cardEndDigits}>
        <div>{t(langKeys.transactions_are_secure_encrypted)}.</div>
        <RadioGroup
          hideRadio
          value="use-stored-payment"
          options={[
            {
              value: "use-stored-payment",
              render: () => (
                <MText variant="subtitle">
                  {t(langKeys.use_stored_payment_method)}
                </MText>
              ),
              renderSecondary: () => (
                <CardInfo>
                  <CardIcon width="24px" />
                  <MText variant="body">
                    {t(langKeys.card_ending_in)}{" "}
                    <MText variant="subtitle" as="span">
                      {cardEndDigits}
                    </MText>
                  </MText>
                </CardInfo>
              ),
            },
          ]}
          onChange={() => {}}
        />

        <BillingAddressSection>
          <MText variant="subtitle">{t(langKeys.stored_billing_address)}</MText>
          <MText variant="subtitleS">
            <PrintedAddressSummary
              billingAddress={billingAddress}
              vatNumber={vatNumber}
            />
          </MText>
        </BillingAddressSection>

        <MBanner type="info">
          <MText as="span">
            {t(langKeys.use_different_card_instructions)}.
          </MText>{" "}
          <InternalLink to={Routes.AccountBilling.location()}>
            {t(langKeys.edit_settings)}
          </InternalLink>
        </MBanner>
      </Show>
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.XL};
`

const CardInfo = styled(MText).attrs(() => ({ variant: "body" }))`
  display: flex;
  place-items: center;
  gap: ${spacing.XS};
  background-color: ${backgroundGrayV2};
`

const BillingAddressSection = styled.div`
  display: grid;
  gap: ${spacing.XS2};
`

const StyledAppLink = styled(InternalLink)`
  white-space: nowrap;
`
